export default {
  getReferralApplication: (state) =>
    state.referrals && state.referrals.referralApplication
      ? state.referrals.referralApplication
      : {},
  getSettlementList: (state) => state.settlementList,
  getReferralList: (state) => state.referralList,
  getReferralListData: (state) => state.referralListData,
  getTableSettings: (state) => state.tableSettings,
  getIsTableLoading: (state) => state.isTableLoading,
  getAdvancedSearchDetail: (state) => state.advancedSearchDetail,
  getAvgNAF: (state) => state.avgNAF,
  getTotalCommission: (state) => state.totalCommission,
}
