import { DEFAULT_VEHICLE_GROUP_OBJECT } from '@/constants/referrals'
export default () => ({
  referralApplication: DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType: '' }),
  isLoading: false,
  applicationStatus: '',
  tableSettings: {
    totalDocs: 0,
    page: 0,
    totalPages: 0,
    limit: 0,
    pagingCounter: 0,
  },
  isTableLoading: false,
  settlementList: [],
  referralList: [],
  referralListData: [],
  advancedSearchDetail: null,
  avgNAF: 0,
  totalCommission: 0,
})
